/*
 * Usher Group new production environment
 * - Created by Callan Acton @ In The Code (05/05/2022)
 */
export const environment = {
	env: 'ushergroup',
	production: true,
	intercomAppId: 'ssl1wxqp',
	googleMapsAPIKey: 'AIzaSyC4OOpkzFJ-6WFzRHcBqG-H7XYOjxs7iaI',
	stripePublishableAPIKey: 'pk_test_51J5Kh0EgCSMDz09z954E4iv4eg7sLGMT7uLao43bHcwhO3fZSGsQfVD5OW5KMOfIkWHMqTY2K8FDm1UWjyLzG4ea00an4BitbW',
	colors: {
		primary: '#98d229',
		primary2: '#65a30d',
		primary3: '#65a30d',
		accentWhite: '#f7fee7',
		accent1: '#d9f99d',
		accent2: '#a3e635',
		lightPrimary: '#F7FEE7'
	},
	stripePlans: [],
	posthog: true,
	countries: ['australia'],
	us: false,
	baseUrl: 'https://ushergroup.paintprojex.com'
};
